*, *:before, *:after
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-family: 'Proxima Nova'
  text-decoration: none
  font-weight: 400
  color: #fff
  outline: none
  padding: 0
  margin: 0
  box-sizing: border-box
  -webkit-box-sizing: border-box

body
  width: 100%
  height: 100%
  overflow: hidden

.widget-section
  width: 100%
  height: 100%
  .widget
    width: 100%
    height: 100%
    &-logo
      img
        width: 164px !important
        height: 164px !important
        margin-top: -30px !important
        @media only screen and (max-width: 1100px)
          width: 116px !important
          height: 116px !important
        @media only screen and (max-width: 670px)
          width: 108px !important
          height: 108px !important
          margin-top: -10px !important
        @media only screen and (max-width: 374px)
          width: 84px !important
          height: 84px !important
