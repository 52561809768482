*, :before, :after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  padding: 0;
  font-family: Proxima Nova;
  font-weight: 400;
  text-decoration: none;
}

body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.widget-section, .widget-section .widget {
  width: 100%;
  height: 100%;
}

.widget-section .widget-logo img {
  width: 164px !important;
  height: 164px !important;
  margin-top: -30px !important;
}

@media only screen and (width <= 1100px) {
  .widget-section .widget-logo img {
    width: 116px !important;
    height: 116px !important;
  }
}

@media only screen and (width <= 670px) {
  .widget-section .widget-logo img {
    width: 108px !important;
    height: 108px !important;
    margin-top: -10px !important;
  }
}

@media only screen and (width <= 374px) {
  .widget-section .widget-logo img {
    width: 84px !important;
    height: 84px !important;
  }
}
/*# sourceMappingURL=index.d797963f.css.map */
